<template>
  <div>
    <w-top-bar>Configuración</w-top-bar>
    <div class="h-full p-5 text-center bg-gray-200">
      <div
        v-if="status === 'waiting'"
        class="flex flex-col items-center justify-center"
      >
        <svg
          class="w-8 h-8 my-5 text-base-800 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div class="w-full py-2 text-gray-800">
          Procesando Integración con Mercado Pago
        </div>
      </div>
      <div
        v-if="status === 'success'"
        class="flex flex-col items-center justify-center"
      >
        <svg
          class="w-8 h-8 my-5 text-green-800"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <div class="w-full py-2 text-gray-800">
          La Integración se enlazó correctamente
        </div>
      </div>
      <div
        v-if="status === 'error'"
        class="flex flex-col items-center justify-center"
      >
        <svg
          class="w-8 h-8 my-5 text-red-800"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <div class="w-full py-2 text-red-800">Ocurrio un error inesperado</div>
        <div class="w-full text-red-800">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      status: "waiting",
      savedToken: null,
      errorMessage: null,
    };
  },
  async mounted() {
    this.authorize();
    // Create, maybe verify if exists and replace it.
  },
  computed: {
    token() {
      return {
        code: this.$route.query.code,
      };
    },
  },
  methods: {
    ...mapActions("tokens", { create: "create" }),
    async authorize() {
      console.log(this.token);
      const result = await this.create(this.token);
      if (result.error) {
        this.errorMessage = result.error.message;
        this.status = "error";
      } else {
        this.status = "success";
        const self = this;
        setTimeout(() => {
          self.$router.push({
            path: "/settings",
            query: { mercadopago: "success" },
          });
        }, 3000);
      }
      this.savedToken = result;
      console.log(result);
    },
  },
};
</script>

<style lang="scss" scoped></style>
